export function SidebarCollapse () {
    // Hide text sidebar content, so only icons remain
    $('.menu-collapsed').toggleClass('d-none');
    $('.sidebar-submenu').toggleClass('d-none');
    $('.submenu-icon').toggleClass('d-none');
    $('#sidebar-container').toggleClass(
        'sidebar-expanded sidebar-collapsed');

    // Collapse/Expand icon
    let ci = $('#collapse-icon').first()
    if (ci.html() == 'chevron_right') {
        ci.html('chevron_left');
    } else {
        ci.html('chevron_right');
    };
}

// Marks sidebar links as active (for hilight) based on current url.
export function ActivateLink () {
    var loc = window.location.href;
    $('.sidebar-submenu a[href]').each(function(elm, val){
        if( val.href == loc ) {
            var elem = $(this);
            elem.addClass('active');
        }
    });

}

$('document').ready(function() {

    var ctrlStateVars = $("#sidebar_vars_json").html();
    if (ctrlStateVars != undefined) {
        var subMenuId = $.parseJSON(ctrlStateVars)['menu'];
        if (subMenuId != undefined) {
            //console.log("Submenu vars: " + subMenuId);
            var openMenu = $("#" + subMenuId).collapse('toggle');
        }
    }

    // Hide submenus
    // $('#sidebar-row .collapse').collapse('hide');

    // Collapse click for link on bottom
    $('[data-toggle=sidebar-collapse]').click(function() {
        SidebarCollapse();
    });

    /*
       1. Trigger the collapse event through JS instead of data-* in order to
       contol what happens to collapsed menus when the sidebar is
       collapsed or un-collapsed.
       2. Mimic accordion behavior for strip
       3. Prevent clicking on menu to jump to href section (this
       scrolls the page which is undesired behavior)
    */
    $('.sidebar-menu-trigger').on('click', function (e) {
        // un-collapse sidebar but skip state change on menu
        if (document.querySelector('#sidebar-container').
            classList.contains("sidebar-collapsed")) {
            SidebarCollapse();
            // toggle menu
        } else {
            var link = e.currentTarget.attributes['href'].nodeValue;
            var alllinks = $('.sidebar-submenu')
            alllinks.each(function (index) {
                var isShow = $(this).is('.show');
                if (isShow) {
                    $(this).collapse('toggle');
                }
                //console.log( index + ": show(" +
                // JSON.stringify($(this).is('.show')));
            });
            $(link).collapse('toggle');
        }
        // prevent browser to jump to href link
        return false;
    });

    ActivateLink();
});
