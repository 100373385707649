// Generic helper classes for any BL dialogue which shows dynamic
// description-strings when selecting BLs.

// PRIVATE ---------------------------------------------------------------------
// Prep Output Description string
function collectBLDescriptions(sels, objs) {
    var outstr = "<ul style='margin-left: -20px;'>";
    for (var idx in sels) {
        outstr += "<li><b>" +
            objs[sels[idx]]['name'] + "</b>: " +
            objs[sels[idx]]['desc'] +
            "</li>";
    }
    return outstr + "</ul>";
}

// PUBLIC ----------------------------------------------------------------------

// Attach text to element
export function attachBLDescription(
    menuVals,
    htmlSelector,
    target,
    label
) {
    if (typeof target != 'undefined') {
        if (menuVals == null) { htmlSelector.text = ""; }
        else {
            var o = "<b>" + label + ":</b>" +
                collectBLDescriptions(menuVals, target);
            htmlSelector.html($(o));
        }
    }
}
