import {attachBLDescription}  from './blDescriptions.js';

$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var href_target = $(e.target).attr('href');
    var src = $('section[class*=frontpage-nav] li.active');
    src.removeClass('active');
    $('.nav-pills > li > a[href$="' + href_target + '"]').parent().addClass('active');
});

export function pages_bl_selector_change(){
    attachBLDescription(
        $("#pages_bl_selector").val(),
        $("#pagesBlDescriptionText"),
        window.pagesblDescriptions,
        "Pages BL Descriptions");
}

export function track_enable_date_update(){
    var enable_date_checkbox = document.getElementById("enable-date-checkbox").checked;
    if(enable_date_checkbox==false){
        document.getElementById('datetime_selects').style.display = 'none';
    }else{
        document.getElementById('datetime_selects').style.display = 'initial';
    }
}

$('document').ready(function(){
    //document.getElementById("notice_level_tooltip").tooltip({html: true, placement: "top"});
    pages_bl_selector_change();
});
