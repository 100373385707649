import {attachBLDescription}  from './blDescriptions.js';

export function sp_bl_selector_change(){
    attachBLDescription(
        $("#bl_selector").val(),
        $("#blDescriptionText"),
        window.blDescriptions,
        "System Participation BL Descriptions");
}

export function post_registration_bl_selector_change(){
    attachBLDescription(
        $("#pr_bl_selector").val(),
        $("#prblDescriptionText"),
        window.prblDescriptions,
        "Post Registration BL Descriptions");
}

// Refresh display once at load time
$('document').ready(function(){
    sp_bl_selector_change();
    post_registration_bl_selector_change();
});
