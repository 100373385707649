import {attachBLDescription}  from './blDescriptions.js';

// Dynamically display selector information
export function license_bl_selector_change() {
    attachBLDescription(
        $("#license_bl_selector").val(),
        $("#licenseBlDescriptionText"),
        window.licenseBlDescriptions,
        "License BL Descriptions");
}

// Refresh display once at load time
$('document').ready(function(){
    license_bl_selector_change();
});
