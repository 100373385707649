export function autoAdjustFooter() {
  var h_con = document.querySelector('.emp-content').offsetHeight;  
  if (h_con !== "undefined") {
    var h_top = document.querySelector('nav.navbar').offsetHeight;
    //var h_client = document.body.clientHeight;
    var h_client = window.innerHeight;
    var a_sticky = document.querySelector('.footer');
    var footer_height = a_sticky.offsetHeight;    
    //if ((h_con + h_top + footer_height) < h_client) {
    if ((h_con + footer_height) < h_client) {
      a_sticky.classList.add('sticky-footer');
    } else {
      a_sticky.classList.remove('sticky-footer');
    }
    console.log("con: " + h_con + " top: " + h_top + " footer-h" + footer_height + " client-h:" + h_client);
  };
};

$(document).ready(function(){

  // Add for pill changing height
  $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
    autoAdjustFooter();
  });
  // Add for tabs changing height
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    autoAdjustFooter();
  });
  $('.collapse').on('shown.bs.collapse', function (e) {
    autoAdjustFooter();
  });
  $('.collapse').on('hidden.bs.collapse', function (e) {
    autoAdjustFooter();
  });

});

// Sticky-footer adjust must use window.load instead of document.ready
// This is because page needs to be loaded & rendered. BS4 Tab-Elements are not
// hidden (rendered) yet when document is ready.
$(window).on("load", function(){
   autoAdjustFooter();
});

/* for debugging but could keep it */
$(window).on("resize", function(){
  autoAdjustFooter();
});