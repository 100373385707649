import {attachBLDescription}  from './blDescriptions.js';

// Callback to dynamically display selector information for
// Phase BL
export function leaderboard_management_bl_selector_change(){
    attachBLDescription(
        $("#bl_selector").val(),
        $("#lbBlDescriptionText"),
        window.blDescriptions,
        "Leaderboard BL Descriptions");
};

// Refresh display once at load time
$('document').ready(function(){
    leaderboard_management_bl_selector_change();
});
