import {attachBLDescription}  from './blDescriptions.js';

// Dynamically display selector information for
// BL Info
export function asset_access_bl_selector_change(){
    attachBLDescription(
        $("#access_bl_selector").val(),
        $("#accessBlDescriptionText"),
        window.blDescriptions,
        "Asset Access BL Descriptions");
}

$('document').ready(function(){
    asset_access_bl_selector_change();
});
