import {attachBLDescription}  from './blDescriptions.js';

export function psc_bl_selector_change() {
    attachBLDescription(
        $("#psc_bl_selector").val(),
        $("#pscBlDescriptionText"),
        window.pscBlDescriptions,
        "Site Post Creation BL Descriptions");
}

export function ptc_bl_selector_change() {
    attachBLDescription(
        $("#ptc_bl_selector").val(),
        $("#ptcBlDescriptionText"),
        window.ptcBlDescriptions,
        "Team Post Creation BL Descriptions");
}

export function puc_bl_selector_change() {
    attachBLDescription(
        $("#puc_bl_selector").val(),
        $("#pucBlDescriptionText"),
        window.pucBlDescriptions,
        "User Post Creation BL Descriptions");
}

// Refresh display once at load time
$('document').ready(function(){
    psc_bl_selector_change();
    ptc_bl_selector_change();
    puc_bl_selector_change();
});
