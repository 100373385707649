import {attachBLDescription}  from './blDescriptions.js';

export function agreement_bl_selector_change(){
    attachBLDescription(
        $("#bl_selector").val(),
        $("#blDescriptionText"),
        window.blDescriptions,
        "License Agreement BL Descriptions");
}

export function license_status_change_bl_selector_change(){
    attachBLDescription(
        $("#bl_lsc_selector").val(),
        $("#blLscDescriptionText"),
        window.blLscDescriptions,
        "License Agreement Status Change BL Descriptions");
}

// Refresh display once at load time
$('document').ready(function(){
    agreement_bl_selector_change();
    license_status_change_bl_selector_change();
});
