// Iterate through all id:oneOfSelection. If marked selected, show it, else hide it.
export function toggleSelectorVisibility(){
    var debug = false;
    if (typeof $('#oneOfSelection') != 'undefined') {
        var selectors = $('select#oneOfSelection option').each(function() {
            // this. is HTMLOptionElement (from the select element)
            // but we need the upper level div containing it.
            var selectElem = this.parentElement;
            if (selectElem != 'undefined') {
                var selectorElem = this.parentElement.parentElement;
                if (selectorElem != 'undefined') {
                    var idname = this.id.substring(9);
                    var divString = 'partial|' + idname;
                    if (selectorElem != 'undefined') {
                        var divSelector = document.getElementById(divString);
                        if (divSelector && (divSelector != 'undefined')) {
                            if (debug) { console.log(divString); };
                            var parentSelectedStr = selectElem.name.split('|').slice(1);
                            if (this.selected) {
                                divSelector.style.display = "block";
                                selectElem.name = "param_tree|" + parentSelectedStr.join("|");
                            } else {
                                // hide element in form
                                divSelector.style.display = "none";
                                //selectElem.name = "deselected_param|" + idname;
                            }
                            if (debug == true) {
                                console.log("IDNAME: " + idname);
                                console.log("PsSTR: " + parentSelectedStr + ", SEL: " + selectElem.name);
                            }
                        }
                    };
                }
            }
        });
    }
};

// Bootstrap first visibility selection
$('document').ready(function(){
    toggleSelectorVisibility();
});
